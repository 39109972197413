.ce-header {
    /*
    padding: 1em 0;
    margin: 0;
    margin-bottom: -0.9em;
    line-height: 1.5em;
    */
    outline: none;
}

    .ce-header p,
    .ce-header div {
        padding: 0 !important;
        margin: 0 !important;
    }

/**
 * Styles for Plugin icon in Toolbar
 */
.ce-header__icon {
}

.ce-header[contentEditable=true][data-placeholder]::before {
    position: absolute;
    content: attr(data-placeholder);
    color: #707684;
    font-weight: normal;
    display: none;
    cursor: text;
}

.ce-header[contentEditable=true][data-placeholder]:empty::before {
    display: block;
}

.ce-header[contentEditable=true][data-placeholder]:empty:focus::before {
    display: none;
}
