.imagesgrid-tool {
    --bg-color: #cdd1e0;
    --front-color: #388ae5;
    --border-color: #e8e8eb;
}
    .imagesgrid-tool__image {
        position: relative;
        border-radius: 3px;
        overflow: hidden;
        margin-bottom: 10px;
    }
        .imagesgrid-tool__image-picture {
            max-width: 100%;
            vertical-align: bottom;
            display: block;
            z-index:1;
        }

        .imagesgrid-tool__image-filebtn {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            z-index:2;
        }
            .imagesgrid-tool__image-filebtn span {
                display:none;
                margin-left:auto;
                margin-right:auto;

            }
            .imagesgrid-tool__image-filebtn:hover span {
                display: block;
            }


        .imagesgrid-tool__image-preloader {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            z-index: 3;
        }
            .imagesgrid-tool__image-preloader-inner {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-size: cover;
                margin: auto;
                position: relative;
                background-color: var(--bg-color);
                background-position: center center;
            }
                .imagesgrid-tool__image-preloader-inner::after {
                    content: "";
                    position: absolute;
                    z-index: 3;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    border: 2px solid var(--bg-color);
                    border-top-color: var(--front-color);
                    left: 50%;
                    top: 50%;
                    margin-top: -30px;
                    margin-left: -30px;
                    animation: imagesgrid-preloader-spin 2s infinite linear;
                    box-sizing: border-box;
                }





/*
.imagesgrid-tool--empty {
    ^&__image {
      display: none;
    }
  }

.imagesgrid-tool--empty,
.imagesgrid-tool--loading {
    ^&__caption {
      display: none;
    }
  }
*/


.imagesgrid-tool__image--filled .imagesgrid-tool__image-preloader,
.imagesgrid-tool__image--empty .imagesgrid-tool__image-preloader {
    display: none !important;
}

.imagesgrid-tool__image--loading .imagesgrid-tool__image {
    min-height: 200px;
    display: flex;
    border: 1px solid var(--border-color);
    background-color: #fff;
}
.imagesgrid-tool__image--loading .imagesgrid-tool__image-picture {
    display: none;
}
.imagesgrid-tool__image--loading .imagesgrid-tool__image-filebtn {
    display: none;
}





@keyframes imagesgrid-preloader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
