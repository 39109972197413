@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&family=Poppins:wght@100&display=swap');

html {
    min-height: 100%;
    display: flex;
}

body {
    flex: 1;
    width:100%;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.85rem;
    color: #000;
    background-color: #fff; /*#f5f7fb; #f8f8f8;*/
}


a {
    color: #000;
    text-decoration: underline;
}
.btn-link {
    color: #000;
}
    a:hover,
    .btn-link:hover {
        color: #666;
    }

    a.btn {
        text-decoration: none;
    }

h1, h2, h3, h4, h5 {
    font-family: 'Poppins', sans-serif;
}

    h1 .dep-title {
        padding-right: 0.75rem;
    }
        h1 .dep-title::after {
            padding-left: 1.75rem;
            content: '';
            background-repeat:no-repeat;
            background-size:20px 20px;
            background-position:right center;
            background-image:url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZGF0YS1uYW1lPSJMYXllciAxIiBpZD0iTGF5ZXJfMSIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBvbHlnb24gcG9pbnRzPSIxNTAuNDYgNDc4IDEyOS44NiA0NTYuNSAzMzkuMTEgMjU2IDEyOS44NiA1NS40OSAxNTAuNDYgMzQgMzgyLjE0IDI1NiAxNTAuNDYgNDc4Ii8+PC9zdmc+);
        }

.dropdown-item {
    font-size: 0.85rem;
    text-decoration:none;
}

.breadcrumb {
    background-color: #f8f8f8 !important;
}


.box-shadow {
    box-shadow: 0 0 0 1px rgb(56 60 67 / 5%), 0 1px 3px 0 rgb(56 60 67 / 15%);
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}


.icon {
    width:15px;
    height:15px;
    display: inline-block;
    content: "";
    vertical-align: -.125em;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
}
.icon.icon-help {
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 48 48' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h48v48H0z' fill='none'/><path d='M24 4C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm2 34h-4v-4h4v4zm4.13-15.49l-1.79 1.84C26.9 25.79 26 27 26 30h-4v-1c0-2.21.9-4.21 2.34-5.66l2.49-2.52C27.55 20.1 28 19.1 28 18c0-2.21-1.79-4-4-4s-4 1.79-4 4h-4c0-4.42 3.58-8 8-8s8 3.58 8 8c0 1.76-.71 3.35-1.87 4.51z'/></svg>");
}



/* FORMS */
.formbox-savebtn {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
    margin-bottom: -1.5rem !important;
    border-top: 1px solid #ebebeb;
    background-color: #f0f0f0;
}
.texteditor.form-control {
    height: auto;
    min-height: 65px;
    max-height: 190px;
    overflow: auto;
}
    .texteditor.form-control.lrg {
        min-height: 90px;
    }


/***
    Hide arrows/spinners from input number
***/
/* Chrome, Safari, Edge, Opera */
input.simpletxt::-webkit-outer-spin-button,
input.simpletxt::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number].simpletxt {
    -moz-appearance: textfield;
}


.table tbody tr.warning {
    background-color: #f2dede !important;
}




/*identity*/
.main-sign {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    margin: auto;
}
    .main-sign.mw-500px {
        max-width: 500px;
    }
    .main-sign.mw-600px {
        max-width: 600px;
    }

    .main-sign .brand-lg {
        white-space: normal;
        text-align: center;
        word-break: break-all;
        background: url(https://res.cloudinary.com/flairfashion/image/upload/c_scale,f_auto,h_100,q_auto:best/v1625645483/TestSite/Logos/flair-designer-portal.jpg) no-repeat 0 0;
        width: 240px;
        height: 40px;
        background-size: 240px 40px;
        background-position: center center;
        display: block;
        text-indent: -999px;
        overflow: hidden;
        margin: 0 auto 0.5rem auto;
    }



