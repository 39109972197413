.slider-next.btn.btn-outline-dark,
.slider-prev.btn.btn-outline-dark {
    background-color: #fff;
    color: #000;
}
.slider-next.btn,
.slider-prev.btn {
    font-size: .85rem;
    border-radius: 0;
    background-color: #000;
    border-color: #000;
    color: #fff;
    text-transform: uppercase;
    line-height: 1.3;
}

.slider-prev, .slider-next {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 12px;
    border-color: #bbb !important;
}

    .slider-prev.disabled, .slider-next.disabled {
        opacity: 0.4;
    }

.slider-prev {
    background-image: url(https://res.cloudinary.com/flairfashion/image/upload/a_90,c_scale,dpr_auto,h_128/v1643476600/TestSite/General/arrow-down.png);
}

.slider-next {
    background-image: url(https://res.cloudinary.com/flairfashion/image/upload/a_270,c_scale,dpr_auto,h_128/v1643476600/TestSite/General/arrow-down.png);
}


/*
@media (max-width: 992px) {
    .slider-controller {
        position: relative;
    }
    .slider-controller .btn-group {
        position: absolute;
        top: 0;
        right: 0;
    }
}
*/
@media (min-width: 992px) {
    .slider-controller {
        position: absolute;
        top: 0;
        right: 0;
        margin-bottom: 0.65rem !important;
        margin-top: 0.65rem !important;
    }
}


.glider-track {
    scrollbar-width: none;
}

.glider::-webkit-scrollbar {
    opacity: 0;
    height: 0;
}

@media (max-width: 36em) {
    .glider::-webkit-scrollbar {
        opacity: 0 !important;
        height: 0 !important;
        /*
        opacity: 1;
        -webkit-appearance: none;
        width: 7px;
        height: 3px;
        */
    }

    .glider::-webkit-scrollbar-thumb {
        opacity: 0 !important;
        height: 0 !important;
        /*
        opacity: 1;
        border-radius: 99px;
        background-color: rgba(156,156,156,.25);
        box-shadow: 0 0 1px rgb(255 255 255 / 25%);
        */
    }
}


/*** style ***/
.glider-itemsalign-middle .glider-track {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}
.glider-itemsalign-bottom .glider-track {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}