.dnd-overlay {
    z-index:99999;
}
.dnd-sortableitem .dnd-movebtn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    text-indent: -200%;
    overflow: hidden;
    cursor: move;
}
.dnd-sortableitem .dnd-removebtn {
    display: flex;
    visibility: hidden;
    position: absolute;
    z-index: 11;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    padding: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    opacity:0.5;
}
.dnd-sortableitem:hover .dnd-removebtn {
    visibility: visible;
    opacity: 0.8;
}
.dnd-sortableitem .dnd-removebtn:hover {
    opacity: 1 !important;
}