.flair-pillar a.t {
    font-size: 0.9em;
    position: relative;
    padding-right: 30px;
    /*background-color: #eaeaea;*/
    text-decoration:none;
    color:#666;
}

    .flair-pillar a.t::after {
        content: ' ';
        position: relative;
        display: inline-block;
        background: no-repeat 50% / 100% 100%;
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cG9seWdvbiBwb2ludHM9IjE2MCwxMTUuNCAxODAuNyw5NiAzNTIsMjU2IDE4MC43LDQxNiAxNjAsMzk2LjcgMzEwLjUsMjU2ICIvPjwvc3ZnPg==");
        background-size: 20px;
        width: 20px;
        height: 14px;
        margin-top: 0px;
        margin-left: 10px;
        opacity: 0.5;
        transition: all 0.5s;
    }

    .flair-pillar a.t.active,
    .flair-pillar a.t:active,
    .flair-pillar a.t:hover {
        color: #000;
    }

    .flair-pillar a.t.active::after,
    .flair-pillar a.t:active::after,
    .flair-pillar a.t:hover::after {
        opacity: 1;
    }

    .flair-pillar a.t.active::after {
        opacity: 1;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
    }
