/*** customise video player ***/
.vjs-theme-sea .vjs-progress-control .vjs-play-progress {
    background-color: #3059AD !important;
}

.video-js:hover .vjs-big-play-button, .video-js .vjs-big-play-button:focus {
    background-color: #3059AD !important;
}

.video-js video:focus {
    outline: none;
}
