@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&family=Poppins:wght@100&display=swap);
a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

.sidemenu .sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: 600;
    padding: 1.5rem 1.5rem 0.375rem;
}

.navbar-brand {
    background: url(https://res.cloudinary.com/flairfashion/image/upload/c_scale,f_auto,h_100,q_auto:best/v1625645483/TestSite/Logos/flair-designer-portal.jpg) no-repeat 0 0;
    width: 160px;
    height: 29px;
    background-size: 160px 29px;
    display: block;
    text-indent: -999px;
    overflow: hidden;
    margin: 1.5rem auto 1.5rem 0;
}


@media (min-width: 768px) {
    header {
        position: fixed;
    }
    .sidemenu {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 100;
        padding: 0;
        background-color:#fff;
        width:228px;
        margin-bottom:0 !important;
    }
        .sidemenu.box-shadow {
            box-shadow: inset -1px 0 0 rgb(0 0 0 / 10%);
        }

        .sidemenu .sidebar-sticky {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            /*height: calc(100vh - 48px);*/
            height: 100vh;
            padding-top: .5rem;
            overflow-x: hidden;
            overflow-y: auto;
            margin:0;
            padding:0;
        }

        .sidemenu .navbar-nav {
            flex-direction: column;
        }
        .sidemenu .nav-link {
            display: block;
            padding: 0.25rem 1.5rem !important;
            text-decoration:none;
        }

    .navbar-brand {
        background: url(https://res.cloudinary.com/flairfashion/image/upload/c_scale,f_auto,h_100,q_auto:best/v1625645483/TestSite/Logos/flair-designer-portal.jpg) no-repeat 0 0;
        width: 160px;
        height: 29px;
        background-size: 160px 29px;
        display: block;
        text-indent: -999px;
        overflow: hidden;
        margin: 1.5rem auto 1.5rem 1.5rem;
    }
}

.myaccount-menu.dropdown .dropdown-menu {
    z-index: 99999;
}

@media (min-width: 768px) {
    .main {
        padding-left: 238px;
        padding-right: 10px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.side-panel {
    padding:0;
    position: fixed;
    top:0;
    right:0;
    height:100%;
    width:100%;
    z-index:9999;
}


.side-panel__content {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    height: 100%;
    width: 70%;
    max-width: 600px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 2rem rgba(0, 0, 255, 0.1);
    /*transform: translateX(-200%);
    transition: transform 300ms linear;*/
    overflow: auto;
}
.side-panel__overlay {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.4;
}


.slideanim-enter .side-panel__content {
    -webkit-transform: translateX(600px);
            transform: translateX(600px);
}
.slideanim-enter.slideanim-enter-active .side-panel__content,
.slideanim2-enter.slideanim2-enter-active .side-panel__content {
    -webkit-animation: slide-in 250ms forwards;
    animation: slide-in 250ms forwards;
}

.slideanim-exit.slideanim-exit-active .side-panel__content,
.slideanim2-exit.slideanim2-exit-active .side-panel__content {
    -webkit-animation: slide-out 250ms forwards;
    animation: slide-out 250ms forwards;
}

@keyframes slide-in {
    0% {
        -webkit-transform: translateX(600px);
                transform: translateX(600px);
    }

    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@-webkit-keyframes slide-in {
    0% {
        -webkit-transform: translateX(600px);
    }

    100% {
        -webkit-transform: translateX(0);
    }
}


@keyframes slide-out {
    0% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(600px);
                transform: translateX(600px);
    }
}

@-webkit-keyframes slide-out {
    0% {
        -webkit-transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(600px);
    }
}






.slideanim-enter .side-panel__overlay {
    opacity: 0;
}

.slideanim-enter.slideanim-enter-active .side-panel__overlay,
.slideanim2-enter.slideanim2-enter-active .side-panel__overlay {
    -webkit-animation: fade-in 250ms forwards;
    animation: fade-in 250ms forwards;
}

.slideanim-exit.slideanim-exit-active .side-panel__overlay,
.slideanim2-exit.slideanim2-exit-active .side-panel__overlay {
    -webkit-animation: fade-out 250ms forwards;
    animation: fade-out 250ms forwards;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.4;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.4;
    }
}

@-webkit-keyframes fade-out {
    0% {
        opacity: 0.4;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fade-out {
    0% {
        opacity: 0.4;
    }

    100% {
        opacity: 0;
    }
}


.disable-action {
    text-decoration:line-through !important;
}

/*
.fade-enter {
    opacity: 0;
    z-index: 1;
    transition: opacity 1250ms ease-in;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 1250ms ease-in;
}
.fade-exit.fade-exit-active {
    opacity: 0 !important;
    transition: opacity 1250ms ease-out !important;
}
*/
.input-group-text {
    background-color:#fff;
}

.dnd-overlay {
    z-index:99999;
}
.dnd-sortableitem .dnd-movebtn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    text-indent: -200%;
    overflow: hidden;
    cursor: move;
}
.dnd-sortableitem .dnd-removebtn {
    display: flex;
    visibility: hidden;
    position: absolute;
    z-index: 11;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    padding: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    opacity:0.5;
}
.dnd-sortableitem:hover .dnd-removebtn {
    visibility: visible;
    opacity: 0.8;
}
.dnd-sortableitem .dnd-removebtn:hover {
    opacity: 1 !important;
}
.ce-textbox .text-paragraph {
    line-height: 1.6em;
    outline: none;
}

    .ce-textbox .text-paragraph[data-placeholder]:empty::before {
        content: attr(data-placeholder);
        color: #707684;
        font-weight: normal;
        opacity: 0;
    }

/** Show placeholder at the first paragraph if Editor is empty */
.codex-editor--empty .ce-block:first-child .ce-textbox .text-paragraph[data-placeholder]:empty::before {
    opacity: 1;
}

.codex-editor--toolbox-opened .ce-block:first-child .ce-textbox .text-paragraph[data-placeholder]:empty::before,
.codex-editor--empty .ce-block:first-child .ce-textbox .text-paragraph[data-placeholder]:empty:focus::before {
    opacity: 0;
}

.ce-textbox .text-paragraph p:first-of-type {
    margin-top: 0;
}

.ce-textbox .text-paragraph p:last-of-type {
    margin-bottom: 0;
}

.ce-header {
    /*
    padding: 1em 0;
    margin: 0;
    margin-bottom: -0.9em;
    line-height: 1.5em;
    */
    outline: none;
}

    .ce-header p,
    .ce-header div {
        padding: 0 !important;
        margin: 0 !important;
    }

/**
 * Styles for Plugin icon in Toolbar
 */
.ce-header__icon {
}

.ce-header[contentEditable=true][data-placeholder]::before {
    position: absolute;
    content: attr(data-placeholder);
    color: #707684;
    font-weight: normal;
    display: none;
    cursor: text;
}

.ce-header[contentEditable=true][data-placeholder]:empty::before {
    display: block;
}

.ce-header[contentEditable=true][data-placeholder]:empty:focus::before {
    display: none;
}

body {
}

.banner-tool {
    --bg-color: #cdd1e0;
    --front-color: #388ae5;
    --border-color: #e8e8eb;
}
    .banner-tool__image {
        position: relative;
        border-radius: 3px;
        overflow: hidden;
        margin-bottom: 10px;
    }
        .banner-tool__image-picture {
            max-width: 100%;
            vertical-align: bottom;
            display: block;
            z-index:1;
        }

        .banner-tool__image-filebtn {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            z-index:2;
        }
            .banner-tool__image-filebtn span {
                display:none;
                margin-left:auto;
                margin-right:auto;

            }
            .banner-tool__image-filebtn:hover span {
                display: block;
            }


        .banner-tool__image-preloader {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            z-index: 3;
        }
            .banner-tool__image-preloader-inner {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-size: cover;
                margin: auto;
                position: relative;
                background-color: var(--bg-color);
                background-position: center center;
            }
                .banner-tool__image-preloader-inner::after {
                    content: "";
                    position: absolute;
                    z-index: 3;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    border: 2px solid var(--bg-color);
                    border-top-color: var(--front-color);
                    left: 50%;
                    top: 50%;
                    margin-top: -30px;
                    margin-left: -30px;
                    -webkit-animation: banner-preloader-spin 2s infinite linear;
                            animation: banner-preloader-spin 2s infinite linear;
                    box-sizing: border-box;
                }


    .banner-tool__caption {
        display:none;
    }
    .banner-tool__caption .cdx-input[contentEditable="true"] {
        display:block;
        border: 0;
        box-shadow: none;
        border-radius: 0;
        padding: 10px 12px;
        outline: none;
        width: 100%;
        box-sizing: border-box;
    }
    .banner-tool__caption .cdx-input[contentEditable="true"][data-placeholder]::before {
        position: absolute !important;
        content: attr(data-placeholder);
        color: #707684;
        font-weight: normal;
        display: none;
    }

        .banner-tool__caption .cdx-input[contentEditable="true"][data-placeholder]:empty::before {
            display: block;
        }

        .banner-tool__caption .cdx-input[contentEditable="true"][data-placeholder]:empty:focus::before {
            display: none;
        }


/*
.banner-tool--empty {
    ^&__image {
      display: none;
    }
  }

.banner-tool--empty,
.banner-tool--loading {
    ^&__caption {
      display: none;
    }
  }
*/


.banner-tool--filled .banner-tool__image-preloader,
.banner-tool--empty .banner-tool__image-preloader {
    display: none !important;
}

.banner-tool--loading .banner-tool__image {
    min-height: 200px;
    display: flex;
    border: 1px solid var(--border-color);
    background-color: #fff;
}
.banner-tool--loading .banner-tool__image-picture {
    display: none;
}
.banner-tool--loading .banner-tool__image-filebtn {
    display: none;
}
.banner-tool--showCaption .banner-tool__caption {
    display: block;
}




  /**
   * Tunes
   * ----------------
   */

.banner-tool--withBorder .banner-tool__image {
    border: 1px solid var(--border-color);
}

.banner-tool--withBackground .banner-tool__image {
    padding: 15px;
    background: var(--bg-color);
}
.banner-tool--withBackground .banner-tool__image-picture {
    max-width: 60%;
    margin: 0 auto;
}

.banner-tool--stretched .banner-tool__image-picture {
    width: 100%;
}



@-webkit-keyframes banner-preloader-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



@keyframes banner-preloader-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.textwithmedia-block {
    --bg-color: #cdd1e0;
    --front-color: #388ae5;
    --border-color: #e8e8eb;
}


@media (min-width: 768px) {
    .textwithmedia-block__sidetxt {
        max-width: 80%;
        min-width:350px;
    }
    .textwithmedia-block__sidetxt.smallbox {
        max-width: 450px;
    }
}



    .textwithmedia-block__media {
        position: relative;
        border-radius: 3px;
        overflow: hidden;
        margin-bottom: 10px;
    }
        .textwithmedia-block__media-picture {
            max-width: 100%;
            vertical-align: bottom;
            display: block;
            z-index:1;
        }

        .textwithmedia-block__media-filebtn {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            z-index:2;
        }
            .textwithmedia-block__media-filebtn span {
                display:none;
                margin-left:auto;
                margin-right:auto;

            }
            .textwithmedia-block__media-filebtn:hover span {
                display: block;
            }


        .textwithmedia-block__media-preloader {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            z-index: 3;
        }
            .textwithmedia-block__media-preloader-inner {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-size: cover;
                margin: auto;
                position: relative;
                background-color: var(--bg-color);
                background-position: center center;
            }
                .textwithmedia-block__media-preloader-inner::after {
                    content: "";
                    position: absolute;
                    z-index: 3;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    border: 2px solid var(--bg-color);
                    border-top-color: var(--front-color);
                    left: 50%;
                    top: 50%;
                    margin-top: -30px;
                    margin-left: -30px;
                    -webkit-animation: textwithmedia-preloader-spin 2s infinite linear;
                            animation: textwithmedia-preloader-spin 2s infinite linear;
                    box-sizing: border-box;
                }


    .textwithmedia-block__media-caption {
        display:none;
    }
    .textwithmedia-block--captionOn .textwithmedia-block__media-caption {
        display:block;
    }
    .textwithmedia-block__media-caption .cdx-input[contentEditable="true"] {
        display:block;
        border: 0;
        box-shadow: none;
        border-radius: 0;
        padding: 10px 12px;
        outline: none;
        width: 100%;
        box-sizing: border-box;
    }
    .textwithmedia-block__media-caption .cdx-input[contentEditable="true"][data-placeholder]::before {
        position: absolute !important;
        content: attr(data-placeholder);
        color: #707684;
        font-weight: normal;
        display: none;
    }

        .textwithmedia-block__media-caption .cdx-input[contentEditable="true"][data-placeholder]:empty::before {
            display: block;
        }

        .textwithmedia-block__media-caption .cdx-input[contentEditable="true"][data-placeholder]:empty:focus::before {
            display: none;
        }



    .textwithmedia-block__text .cdx-input[contentEditable="true"] {
        display:block;
        border: 0;
        box-shadow: none;
        border-radius: 0;
        padding: 10px 12px;
        outline: none;
        width: 100%;
        box-sizing: border-box;
    }
    .textwithmedia-block__text .cdx-input[contentEditable="true"][data-placeholder]::before {
        position: absolute !important;
        content: attr(data-placeholder);
        color: #707684;
        font-weight: normal;
        display: none;
    }

        .textwithmedia-block__text .cdx-input[contentEditable="true"][data-placeholder]:empty::before,
        .textwithmedia-block__text .cdx-input.empty[contentEditable="true"][data-placeholder]::before {
            display: block;
        }

        .textwithmedia-block__text .cdx-input[contentEditable="true"][data-placeholder]:empty:focus::before,
        .textwithmedia-block__text .cdx-input.empty[contentEditable="true"][data-placeholder]:focus::before {
            display: none;
        }

/*
.banner-tool--empty {
    ^&__image {
      display: none;
    }
  }

.banner-tool--empty,
.banner-tool--loading {
    ^&__caption {
      display: none;
    }
  }
*/


.textwithmedia-block--filled .textwithmedia-block__media-preloader,
.textwithmedia-block--empty .textwithmedia-block__media-preloader {
    display: none !important;
}

.textwithmedia-block--loading .textwithmedia-block__media {
    min-height: 200px;
    display: flex;
    border: 1px solid var(--border-color);
    background-color: #fff;
}
.textwithmedia-block--loading .textwithmedia-block__media-picture {
    display: none;
}
.textwithmedia-block--loading .textwithmedia-block__media-filebtn {
    display: none;
}




  /**
   * Tunes
   * ----------------
   */

.textwithmedia-block--withBorder .textwithmedia-block__image {
    border: 1px solid var(--border-color);
}

.textwithmedia-block--withBackground .textwithmedia-block__image {
    padding: 15px;
    background: var(--bg-color);
}
.textwithmedia-block--withBackground .textwithmedia-block__image-picture {
    max-width: 60%;
    margin: 0 auto;
}

.textwithmedia-block--stretched .textwithmedia-block__image-picture {
    width: 100%;
}



@-webkit-keyframes textwithmedia-preloader-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



@keyframes textwithmedia-preloader-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.video-tool {
    --bg-color: #cdd1e0;
    --front-color: #388ae5;
    --border-color: #e8e8eb;
}
    .block-tool__video {
        position: relative;
        border-radius: 3px;
        overflow: hidden;
        margin-bottom: 10px;
    }
        .block-tool__video-picture {
            max-width: 100%;
            vertical-align: bottom;
            display: block;
            z-index:1;
        }

        .block-tool__video-filebtn {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            z-index:2;
        }
            .block-tool__video-filebtn span {
                display:none;
                margin-left:auto;
                margin-right:auto;

            }
            .block-tool__video-filebtn:hover span {
                display: block;
            }


        .block-tool__video-preloader {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            z-index: 3;
        }
            .block-tool__video-preloader-inner {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-size: cover;
                margin: auto;
                position: relative;
                background-color: var(--bg-color);
                background-position: center center;
            }
                .block-tool__video-preloader-inner::after {
                    content: "";
                    position: absolute;
                    z-index: 3;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    border: 2px solid var(--bg-color);
                    border-top-color: var(--front-color);
                    left: 50%;
                    top: 50%;
                    margin-top: -30px;
                    margin-left: -30px;
                    -webkit-animation: video-preloader-spin 2s infinite linear;
                            animation: video-preloader-spin 2s infinite linear;
                    box-sizing: border-box;
                }


    .block-tool__caption {
        display:none;
    }
    .block-tool__caption .cdx-input[contentEditable="true"] {
        display:block;
        border: 0;
        box-shadow: none;
        border-radius: 0;
        padding: 10px 12px;
        outline: none;
        width: 100%;
        box-sizing: border-box;
    }
    .block-tool__caption .cdx-input[contentEditable="true"][data-placeholder]::before {
        position: absolute !important;
        content: attr(data-placeholder);
        color: #707684;
        font-weight: normal;
        display: none;
    }

        .block-tool__caption .cdx-input[contentEditable="true"][data-placeholder]:empty::before {
            display: block;
        }

        .block-tool__caption .cdx-input[contentEditable="true"][data-placeholder]:empty:focus::before {
            display: none;
        }


/*
.block-tool--empty {
    ^&__image {
      display: none;
    }
  }

.block-tool--empty,
.block-tool--loading {
    ^&__caption {
      display: none;
    }
  }
*/


.video-tool--filled .block-tool__video-preloader,
.video-tool--empty .block-tool__video-preloader {
    display: none !important;
}

.video-tool--loading .block-tool__video {
    min-height: 200px;
    /*display: flex;*/
    border: 1px solid var(--border-color);
    background-color: #fff;
}
.video-tool--loading .block-tool__video-picture {
    display: none;
}
.video-tool--loading .block-tool__video-filebtn,
.video-tool--filled .block-tool__video-filebtn {
    display: none !important;
}
.video-tool--showCaption .block-tool__caption {
    display: block;
}




  /**
   * Tunes
   * ----------------
   */

.block-tool--withBorder .block-tool__video {
    border: 1px solid var(--border-color);
}

.block-tool--withBackground .block-tool__video {
    padding: 15px;
    background: var(--bg-color);
}
.block-tool--withBackground .block-tool__video-picture {
    max-width: 60%;
    margin: 0 auto;
}

.block-tool--stretched .block-tool__video-picture {
    width: 100%;
}



@-webkit-keyframes video-preloader-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



@keyframes video-preloader-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*** customise video player ***/
.vjs-theme-sea .vjs-progress-control .vjs-play-progress {
    background-color: #3059AD !important;
}

.video-js:hover .vjs-big-play-button, .video-js .vjs-big-play-button:focus {
    background-color: #3059AD !important;
}

.video-js video:focus {
    outline: none;
}

.imagescarousel-tool {
    --bg-color: #cdd1e0;
    --front-color: #388ae5;
    --border-color: #e8e8eb;
}
    .imagescarousel-tool__image {
        position: relative;
        border-radius: 3px;
        overflow: hidden;
        margin-bottom: 10px;
    }
        .imagescarousel-tool__image-picture {
            max-width: 100%;
            vertical-align: bottom;
            display: block;
            z-index:1;
        }

        .imagescarousel-tool__image-filebtn {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            z-index:2;
        }
            .imagescarousel-tool__image-filebtn span {
                display:none;
                margin-left:auto;
                margin-right:auto;

            }
            .imagescarousel-tool__image-filebtn:hover span {
                display: block;
            }


        .imagescarousel-tool__image-preloader {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            z-index: 3;
        }
            .imagescarousel-tool__image-preloader-inner {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-size: cover;
                margin: auto;
                position: relative;
                background-color: var(--bg-color);
                background-position: center center;
            }
                .imagescarousel-tool__image-preloader-inner::after {
                    content: "";
                    position: absolute;
                    z-index: 3;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    border: 2px solid var(--bg-color);
                    border-top-color: var(--front-color);
                    left: 50%;
                    top: 50%;
                    margin-top: -30px;
                    margin-left: -30px;
                    -webkit-animation: imagescarousel-preloader-spin 2s infinite linear;
                            animation: imagescarousel-preloader-spin 2s infinite linear;
                    box-sizing: border-box;
                }





/*
.imagescarousel-tool--empty {
    ^&__image {
      display: none;
    }
  }

.imagescarousel-tool--empty,
.imagescarousel-tool--loading {
    ^&__caption {
      display: none;
    }
  }
*/


.imagescarousel-tool__image--filled .imagescarousel-tool__image-preloader,
.imagescarousel-tool__image--empty .imagescarousel-tool__image-preloader {
    display: none !important;
}

.imagescarousel-tool__image--loading .imagescarousel-tool__image {
    min-height: 200px;
    display: flex;
    border: 1px solid var(--border-color);
    background-color: #fff;
}
.imagescarousel-tool__image--loading .imagescarousel-tool__image-picture {
    display: none;
}
.imagescarousel-tool__image--loading .imagescarousel-tool__image-filebtn {
    display: none;
}





@-webkit-keyframes imagescarousel-preloader-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}





@keyframes imagescarousel-preloader-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.slider-next.btn.btn-outline-dark,
.slider-prev.btn.btn-outline-dark {
    background-color: #fff;
    color: #000;
}
.slider-next.btn,
.slider-prev.btn {
    font-size: .85rem;
    border-radius: 0;
    background-color: #000;
    border-color: #000;
    color: #fff;
    text-transform: uppercase;
    line-height: 1.3;
}

.slider-prev, .slider-next {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 12px;
    border-color: #bbb !important;
}

    .slider-prev.disabled, .slider-next.disabled {
        opacity: 0.4;
    }

.slider-prev {
    background-image: url(https://res.cloudinary.com/flairfashion/image/upload/a_90,c_scale,dpr_auto,h_128/v1643476600/TestSite/General/arrow-down.png);
}

.slider-next {
    background-image: url(https://res.cloudinary.com/flairfashion/image/upload/a_270,c_scale,dpr_auto,h_128/v1643476600/TestSite/General/arrow-down.png);
}


/*
@media (max-width: 992px) {
    .slider-controller {
        position: relative;
    }
    .slider-controller .btn-group {
        position: absolute;
        top: 0;
        right: 0;
    }
}
*/
@media (min-width: 992px) {
    .slider-controller {
        position: absolute;
        top: 0;
        right: 0;
        margin-bottom: 0.65rem !important;
        margin-top: 0.65rem !important;
    }
}


.glider-track {
    scrollbar-width: none;
}

.glider::-webkit-scrollbar {
    opacity: 0;
    height: 0;
}

@media (max-width: 36em) {
    .glider::-webkit-scrollbar {
        opacity: 0 !important;
        height: 0 !important;
        /*
        opacity: 1;
        -webkit-appearance: none;
        width: 7px;
        height: 3px;
        */
    }

    .glider::-webkit-scrollbar-thumb {
        opacity: 0 !important;
        height: 0 !important;
        /*
        opacity: 1;
        border-radius: 99px;
        background-color: rgba(156,156,156,.25);
        box-shadow: 0 0 1px rgb(255 255 255 / 25%);
        */
    }
}


/*** style ***/
.glider-itemsalign-middle .glider-track {
    align-items: center !important;
    display: flex !important;
}
.glider-itemsalign-bottom .glider-track {
    align-items: flex-end !important;
    display: flex !important;
}
.imagesgrid-tool {
    --bg-color: #cdd1e0;
    --front-color: #388ae5;
    --border-color: #e8e8eb;
}
    .imagesgrid-tool__image {
        position: relative;
        border-radius: 3px;
        overflow: hidden;
        margin-bottom: 10px;
    }
        .imagesgrid-tool__image-picture {
            max-width: 100%;
            vertical-align: bottom;
            display: block;
            z-index:1;
        }

        .imagesgrid-tool__image-filebtn {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            z-index:2;
        }
            .imagesgrid-tool__image-filebtn span {
                display:none;
                margin-left:auto;
                margin-right:auto;

            }
            .imagesgrid-tool__image-filebtn:hover span {
                display: block;
            }


        .imagesgrid-tool__image-preloader {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            z-index: 3;
        }
            .imagesgrid-tool__image-preloader-inner {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-size: cover;
                margin: auto;
                position: relative;
                background-color: var(--bg-color);
                background-position: center center;
            }
                .imagesgrid-tool__image-preloader-inner::after {
                    content: "";
                    position: absolute;
                    z-index: 3;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    border: 2px solid var(--bg-color);
                    border-top-color: var(--front-color);
                    left: 50%;
                    top: 50%;
                    margin-top: -30px;
                    margin-left: -30px;
                    -webkit-animation: imagesgrid-preloader-spin 2s infinite linear;
                            animation: imagesgrid-preloader-spin 2s infinite linear;
                    box-sizing: border-box;
                }





/*
.imagesgrid-tool--empty {
    ^&__image {
      display: none;
    }
  }

.imagesgrid-tool--empty,
.imagesgrid-tool--loading {
    ^&__caption {
      display: none;
    }
  }
*/


.imagesgrid-tool__image--filled .imagesgrid-tool__image-preloader,
.imagesgrid-tool__image--empty .imagesgrid-tool__image-preloader {
    display: none !important;
}

.imagesgrid-tool__image--loading .imagesgrid-tool__image {
    min-height: 200px;
    display: flex;
    border: 1px solid var(--border-color);
    background-color: #fff;
}
.imagesgrid-tool__image--loading .imagesgrid-tool__image-picture {
    display: none;
}
.imagesgrid-tool__image--loading .imagesgrid-tool__image-filebtn {
    display: none;
}





@-webkit-keyframes imagesgrid-preloader-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}





@keyframes imagesgrid-preloader-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.editor-topmenu {
    padding: 0.8rem 1.2rem;
    background-color: rgba(255,255,255, 0.7);
    border-bottom-right-radius: 0.5rem !important;
}

.cdx-settings-divider {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
}
    .cdx-settings-divider:last-child {
        margin-bottom: 0px !important;
    }


@media (hover: hover) {
    .ce-toolbar__plus,
    .ce-toolbar__settings-btn {
        background-color: rgba(255, 255, 255, 0.5);
    }
}




.cdx-textinput[contentEditable="true"] {
    display: block;
    border: 0;
    box-shadow: none;
    border-radius: 0;
    outline: none;
    width: 100%;
    box-sizing: border-box;
}

    .cdx-textinput[contentEditable="true"][data-placeholder]::before {
        position: absolute !important;
        content: attr(data-placeholder);
        color: #707684;
        font-weight: normal;
        display: none;
    }

    .cdx-textinput[contentEditable="true"][data-placeholder]:empty::before,
    .cdx-textinput.empty[contentEditable="true"][data-placeholder]::before {
        display: block;
    }

    .cdx-textinput[contentEditable="true"][data-placeholder]:empty:focus::before,
    .cdx-textinput.empty[contentEditable="true"][data-placeholder]:focus::before {
        display: none;
    }



.w-full, .banner-full {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
}

.ce-block__content, .ce-toolbar__content {
    max-width: calc(100% - 80px) !important;
}

.cdx-block {
    max-width: 100% !important;
}



.simple-image.withBorder img {
    border: 1px solid #e8e8eb;
}

.simple-image.withBackground {
    background: #eff2f5;
    padding: 10px;
}

    .simple-image.withBackground img {
        display: block;
        max-width: 60%;
        margin: 0 auto 15px;
    }



.ce-header,
.ce-textcolumns > div,
.ce-leadtext > div {
    outline: none;
}
.ce-toolbar {
    z-index:9999;
}






@media (min-width: 768px) {
    .story .text-paragraph {
        max-width: 450px;
    }
    .story .text-paragraph.lrg {
        max-width: 650px;
    }
}









@media (min-width: 1200px) {
    .story .mb-5, .story .my-5 {
        margin-bottom: 4rem !important;
    }

    .story .mt-5, .story .my-5 {
        margin-top: 4rem !important;
    }
}



.story h1 {
    font-size: 1.7rem;
    text-align: center;
    text-transform: uppercase;
    margin-right: auto;
    margin-left: auto;
}

.story h2 {
    font-size: 1.2rem;
    text-transform: uppercase;
}

.story .lead {
    font-size: 1rem;
}

    .story .lead.lrg {
        font-size: 1.3rem;
        text-transform: uppercase;
        line-height: 1.2;
    }

    .story .lead.sml {
        font-size: 0.85rem !important;
        font-weight: 400;
    }

@media (min-width: 768px) {
    .story h1 {
        max-width: 1000px;
        font-size: 2rem;
    }

    .story h2 {
        font-size: 1.5rem;
    }

    .story .lead {
        font-size: 1.1rem;
    }

        .story .lead.lrg {
            font-size: 1.5rem;
        }
}

@media (min-width: 992px) {
    .story h1 {
        font-size: 3rem;
    }

    .story h2 {
        font-size: 2rem;
    }

    .story .lead {
        font-size: 1.25rem;
    }

        .story .lead.lrg {
            font-size: 2rem;
        }
}

@media (min-width: 1200px) {
    .story h1 {
        font-size: 5rem;
    }

    .story h2 {
        font-size: 3rem;
    }

    .story .lead.lrg {
        font-size: 3rem;
    }
}

.story .banner {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
}

.story .vid-fullscreen {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
}

@media (min-width: 768px) {
    .story .dualgalleryitem,
    .story .sidetxt {
        max-width: 80%;
    }

    .story .leadbox {
        max-width: 65%;
    }

    .story .sidetxt.smallbox {
        max-width: 450px;
    }
}


.story .txt-columns {
    -webkit-columns: 2 200px;
    columns: 2 200px;
    -webkit-column-gap: 4em;
    -moz-column-gap: 4em;
    grid-column-gap: 4em;
    column-gap: 4em;
}


.story .fixed-bg {
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
}

    .story .fixed-bg.mobile {
        background-attachment: scroll !important;
    }


.story .dualgalleryitem img {
    width: 60%;
}

.story .gallery {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
}

.story .gallery {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
}

    .story .gallery .inner {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important;
    }


.story .textbox {
    max-width: 600px;
}

    .story .textbox.smallboxs {
        max-width: 400px;
    }







.story .story-images-carousel {
    position: relative;
}


.a-l a {
    text-decoration: underline;
}
.a-l header {
    z-index: 9999 !important;
}

@media (min-width: 768px) {
}

.navbar-light {
    background-color:#fff !important;
}

.carousel-home .carousel-caption {
    display: block !important;
}

    .carousel-home .carousel-caption p {
        margin-bottom: 0 !important;
        font-size: 1rem;
    }

@media (max-width: 767.98px) {
    .carousel-home .carousel-caption {
        padding-bottom: 15px !important;
    }
}

.homep-b {
    position: relative;
    overflow: hidden;
}

.homep-b-txt {
    position: relative;
    width: 100%;
    text-transform: uppercase;
    background-color: #fff;
    padding: 2.5rem 1rem 2rem;
    background: none;
}
    .homep-b-txt .header-style {
        font-weight: 300;
        text-transform: uppercase;
        font-size: 1.4rem;
        line-height: 1.1;
        color: #747474;
    }
        .homep-b-txt .header-style .hl {
            color: #000;
        }

.homep-b-credit {
    position: absolute;
    padding: 10px;
    font-size: 9.184px;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.homep-b-credit.right {
    bottom: 0;
    right: 0;
    left: unset;
}


@media (min-width: 768px) {
    .homep-b-txt {
        position: absolute;
        top: 0;
        left: 10%;
        width: 400px;
        height:100%;
        padding: 0;
        z-index: 2;
    }
        .homep-b-txt .header-style {
            font-size: 1.2rem;
            line-height: 1.1;
        }
}

@media (min-width: 992px) {
    .homep-b-txt {
        left: 10%;
        width: 380px;
        background: none;
        padding: 0;
    }
        .homep-b-txt .header-style {
            font-size: 1.5rem;
            line-height: 1.1;
        }
}

@media (min-width: 1200px) {
    .homep-b-txt {
        left: 15%;
        width: 400px;
        background: none;
        padding: 0;
    }
        .homep-b-txt .header-style {
            font-size: 1.6rem;
            line-height: 1.1;
        }
}

@media (min-width: 1350px) {
    .homep-b-txt {
        left: 12%;
        width: 450px;
    }
        .homep-b-txt .header-style {
            font-size: 1.8rem;
            line-height: 1.1;
        }
}

@media (min-width: 1500px) {
    .homep-b-txt {
        left: 15%;
        width: 500px;
    }
        .homep-b-txt .header-style {
            font-size: 2rem;
            line-height: 1.1;
        }
}







#features {
    background-color: #efeeef;
}

#recentdesigners {
    background: linear-gradient(45deg, #2937f0, #9f1ae2) !important;
    color: white !important;
}



#whatisflair .text-inner {
    max-width: 500px;
    margin: 0 auto;
    padding-top: 0px;
}
@media (min-width: 768px) {
    #whatisflair .text-inner {
        padding-top: 120px;
    }
}
@media (min-width: 992px) {
    #whatisflair .text-inner {
        padding-top: 150px;
    }
}
@media (min-width: 1200px) {
    #whatisflair .text-inner {
        padding-top: 200px;
    }
}



.test {
    color: #fff;
    /*
    background-color:#000;
    */
    background: linear-gradient(-45deg, #000, #241a1a, #24201a, #1e241a, #1a2424, #231a24);
    background-size: 400% 400%;
    -webkit-animation: home-gradient 100s ease infinite;
            animation: home-gradient 100s ease infinite;
}
    .test a {
        color: #999;
    }

    @-webkit-keyframes home-gradient {
        0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

    @keyframes home-gradient {
        0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.simple-qa {
    max-width: 900px;
}

    .simple-qa section {
        padding-bottom: 5px;
    }

    .simple-qa a.q {
        font-size: 1.1em;
        position: relative;
        padding-left: 30px;
        padding-right: 20px;
        /*background-color: #eaeaea;*/
    }

        .simple-qa a.q::before {
            content: ' ';
            position: absolute;
            top: 50%;
            left: 10px;
            background: no-repeat 50% / 100% 100%;
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cG9seWdvbiBwb2ludHM9IjE2MCwxMTUuNCAxODAuNyw5NiAzNTIsMjU2IDE4MC43LDQxNiAxNjAsMzk2LjcgMzEwLjUsMjU2ICIvPjwvc3ZnPg==");
            background-size: 15px;
            width: 20px;
            height: 14px;
            margin-top: -6px;
            opacity: 1;
            transition: all 0.5s;
        }

        .simple-qa a.q.active::before,
        .simple-qa a.q:active::before,
        .simple-qa a.q:hover::before {
            opacity: 0.5;
        }

        .simple-qa a.q.active::before {
            opacity: 0.9;
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
        }

.flair-pillar a.t {
    font-size: 0.9em;
    position: relative;
    padding-right: 30px;
    /*background-color: #eaeaea;*/
    text-decoration:none;
    color:#666;
}

    .flair-pillar a.t::after {
        content: ' ';
        position: relative;
        display: inline-block;
        background: no-repeat 50% / 100% 100%;
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cG9seWdvbiBwb2ludHM9IjE2MCwxMTUuNCAxODAuNyw5NiAzNTIsMjU2IDE4MC43LDQxNiAxNjAsMzk2LjcgMzEwLjUsMjU2ICIvPjwvc3ZnPg==");
        background-size: 20px;
        width: 20px;
        height: 14px;
        margin-top: 0px;
        margin-left: 10px;
        opacity: 0.5;
        transition: all 0.5s;
    }

    .flair-pillar a.t.active,
    .flair-pillar a.t:active,
    .flair-pillar a.t:hover {
        color: #000;
    }

    .flair-pillar a.t.active::after,
    .flair-pillar a.t:active::after,
    .flair-pillar a.t:hover::after {
        opacity: 1;
    }

    .flair-pillar a.t.active::after {
        opacity: 1;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

#service-agreement {
    max-width: 900px;
}
    #service-agreement ol > li::marker {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 1.25rem;
    }

html {
    min-height: 100%;
    display: flex;
}

body {
    flex: 1 1;
    width:100%;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.85rem;
    color: #000;
    background-color: #fff; /*#f5f7fb; #f8f8f8;*/
}


a {
    color: #000;
    text-decoration: underline;
}
.btn-link {
    color: #000;
}
    a:hover,
    .btn-link:hover {
        color: #666;
    }

    a.btn {
        text-decoration: none;
    }

h1, h2, h3, h4, h5 {
    font-family: 'Poppins', sans-serif;
}

    h1 .dep-title {
        padding-right: 0.75rem;
    }
        h1 .dep-title::after {
            padding-left: 1.75rem;
            content: '';
            background-repeat:no-repeat;
            background-size:20px 20px;
            background-position:right center;
            background-image:url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZGF0YS1uYW1lPSJMYXllciAxIiBpZD0iTGF5ZXJfMSIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBvbHlnb24gcG9pbnRzPSIxNTAuNDYgNDc4IDEyOS44NiA0NTYuNSAzMzkuMTEgMjU2IDEyOS44NiA1NS40OSAxNTAuNDYgMzQgMzgyLjE0IDI1NiAxNTAuNDYgNDc4Ii8+PC9zdmc+);
        }

.dropdown-item {
    font-size: 0.85rem;
    text-decoration:none;
}

.breadcrumb {
    background-color: #f8f8f8 !important;
}


.box-shadow {
    box-shadow: 0 0 0 1px rgb(56 60 67 / 5%), 0 1px 3px 0 rgb(56 60 67 / 15%);
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}


.icon {
    width:15px;
    height:15px;
    display: inline-block;
    content: "";
    vertical-align: -.125em;
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
}
.icon.icon-help {
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 48 48' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h48v48H0z' fill='none'/><path d='M24 4C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm2 34h-4v-4h4v4zm4.13-15.49l-1.79 1.84C26.9 25.79 26 27 26 30h-4v-1c0-2.21.9-4.21 2.34-5.66l2.49-2.52C27.55 20.1 28 19.1 28 18c0-2.21-1.79-4-4-4s-4 1.79-4 4h-4c0-4.42 3.58-8 8-8s8 3.58 8 8c0 1.76-.71 3.35-1.87 4.51z'/></svg>");
}



/* FORMS */
.formbox-savebtn {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
    margin-bottom: -1.5rem !important;
    border-top: 1px solid #ebebeb;
    background-color: #f0f0f0;
}
.texteditor.form-control {
    height: auto;
    min-height: 65px;
    max-height: 190px;
    overflow: auto;
}
    .texteditor.form-control.lrg {
        min-height: 90px;
    }


/***
    Hide arrows/spinners from input number
***/
/* Chrome, Safari, Edge, Opera */
input.simpletxt::-webkit-outer-spin-button,
input.simpletxt::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number].simpletxt {
    -moz-appearance: textfield;
}


.table tbody tr.warning {
    background-color: #f2dede !important;
}




/*identity*/
.main-sign {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    margin: auto;
}
    .main-sign.mw-500px {
        max-width: 500px;
    }
    .main-sign.mw-600px {
        max-width: 600px;
    }

    .main-sign .brand-lg {
        white-space: normal;
        text-align: center;
        word-break: break-all;
        background: url(https://res.cloudinary.com/flairfashion/image/upload/c_scale,f_auto,h_100,q_auto:best/v1625645483/TestSite/Logos/flair-designer-portal.jpg) no-repeat 0 0;
        width: 240px;
        height: 40px;
        background-size: 240px 40px;
        background-position: center center;
        display: block;
        text-indent: -999px;
        overflow: hidden;
        margin: 0 auto 0.5rem auto;
    }




