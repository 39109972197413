.simple-qa {
    max-width: 900px;
}

    .simple-qa section {
        padding-bottom: 5px;
    }

    .simple-qa a.q {
        font-size: 1.1em;
        position: relative;
        padding-left: 30px;
        padding-right: 20px;
        /*background-color: #eaeaea;*/
    }

        .simple-qa a.q::before {
            content: ' ';
            position: absolute;
            top: 50%;
            left: 10px;
            background: no-repeat 50% / 100% 100%;
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cG9seWdvbiBwb2ludHM9IjE2MCwxMTUuNCAxODAuNyw5NiAzNTIsMjU2IDE4MC43LDQxNiAxNjAsMzk2LjcgMzEwLjUsMjU2ICIvPjwvc3ZnPg==");
            background-size: 15px;
            width: 20px;
            height: 14px;
            margin-top: -6px;
            opacity: 1;
            transition: all 0.5s;
        }

        .simple-qa a.q.active::before,
        .simple-qa a.q:active::before,
        .simple-qa a.q:hover::before {
            opacity: 0.5;
        }

        .simple-qa a.q.active::before {
            opacity: 0.9;
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            transform: rotate(90deg);
        }
