.w-full, .banner-full {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
}

.ce-block__content, .ce-toolbar__content {
    max-width: calc(100% - 80px) !important;
}

.cdx-block {
    max-width: 100% !important;
}



.simple-image.withBorder img {
    border: 1px solid #e8e8eb;
}

.simple-image.withBackground {
    background: #eff2f5;
    padding: 10px;
}

    .simple-image.withBackground img {
        display: block;
        max-width: 60%;
        margin: 0 auto 15px;
    }



.ce-header,
.ce-textcolumns > div,
.ce-leadtext > div {
    outline: none;
}
.ce-toolbar {
    z-index:9999;
}






@media (min-width: 768px) {
    .story .text-paragraph {
        max-width: 450px;
    }
    .story .text-paragraph.lrg {
        max-width: 650px;
    }
}









@media (min-width: 1200px) {
    .story .mb-5, .story .my-5 {
        margin-bottom: 4rem !important;
    }

    .story .mt-5, .story .my-5 {
        margin-top: 4rem !important;
    }
}



.story h1 {
    font-size: 1.7rem;
    text-align: center;
    text-transform: uppercase;
    margin-right: auto;
    margin-left: auto;
}

.story h2 {
    font-size: 1.2rem;
    text-transform: uppercase;
}

.story .lead {
    font-size: 1rem;
}

    .story .lead.lrg {
        font-size: 1.3rem;
        text-transform: uppercase;
        line-height: 1.2;
    }

    .story .lead.sml {
        font-size: 0.85rem !important;
        font-weight: 400;
    }

@media (min-width: 768px) {
    .story h1 {
        max-width: 1000px;
        font-size: 2rem;
    }

    .story h2 {
        font-size: 1.5rem;
    }

    .story .lead {
        font-size: 1.1rem;
    }

        .story .lead.lrg {
            font-size: 1.5rem;
        }
}

@media (min-width: 992px) {
    .story h1 {
        font-size: 3rem;
    }

    .story h2 {
        font-size: 2rem;
    }

    .story .lead {
        font-size: 1.25rem;
    }

        .story .lead.lrg {
            font-size: 2rem;
        }
}

@media (min-width: 1200px) {
    .story h1 {
        font-size: 5rem;
    }

    .story h2 {
        font-size: 3rem;
    }

    .story .lead.lrg {
        font-size: 3rem;
    }
}

.story .banner {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
}

.story .vid-fullscreen {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
}

@media (min-width: 768px) {
    .story .dualgalleryitem,
    .story .sidetxt {
        max-width: 80%;
    }

    .story .leadbox {
        max-width: 65%;
    }

    .story .sidetxt.smallbox {
        max-width: 450px;
    }
}


.story .txt-columns {
    -webkit-columns: 2 200px;
    -moz-columns: 2 200px;
    columns: 2 200px;
    -webkit-column-gap: 4em;
    -moz-column-gap: 4em;
    column-gap: 4em;
}


.story .fixed-bg {
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
}

    .story .fixed-bg.mobile {
        background-attachment: scroll !important;
    }


.story .dualgalleryitem img {
    width: 60%;
}

.story .gallery {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
}

.story .gallery {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
}

    .story .gallery .inner {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important;
    }


.story .textbox {
    max-width: 600px;
}

    .story .textbox.smallboxs {
        max-width: 400px;
    }







.story .story-images-carousel {
    position: relative;
}
