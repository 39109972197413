.navbar-light {
    background-color:#fff !important;
}

.carousel-home .carousel-caption {
    display: block !important;
}

    .carousel-home .carousel-caption p {
        margin-bottom: 0 !important;
        font-size: 1rem;
    }

@media (max-width: 767.98px) {
    .carousel-home .carousel-caption {
        padding-bottom: 15px !important;
    }
}

.homep-b {
    position: relative;
    overflow: hidden;
}

.homep-b-txt {
    position: relative;
    width: 100%;
    text-transform: uppercase;
    background-color: #fff;
    padding: 2.5rem 1rem 2rem;
    background: none;
}
    .homep-b-txt .header-style {
        font-weight: 300;
        text-transform: uppercase;
        font-size: 1.4rem;
        line-height: 1.1;
        color: #747474;
    }
        .homep-b-txt .header-style .hl {
            color: #000;
        }

.homep-b-credit {
    position: absolute;
    padding: 10px;
    font-size: 9.184px;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.homep-b-credit.right {
    bottom: 0;
    right: 0;
    left: unset;
}


@media (min-width: 768px) {
    .homep-b-txt {
        position: absolute;
        top: 0;
        left: 10%;
        width: 400px;
        height:100%;
        padding: 0;
        z-index: 2;
    }
        .homep-b-txt .header-style {
            font-size: 1.2rem;
            line-height: 1.1;
        }
}

@media (min-width: 992px) {
    .homep-b-txt {
        left: 10%;
        width: 380px;
        background: none;
        padding: 0;
    }
        .homep-b-txt .header-style {
            font-size: 1.5rem;
            line-height: 1.1;
        }
}

@media (min-width: 1200px) {
    .homep-b-txt {
        left: 15%;
        width: 400px;
        background: none;
        padding: 0;
    }
        .homep-b-txt .header-style {
            font-size: 1.6rem;
            line-height: 1.1;
        }
}

@media (min-width: 1350px) {
    .homep-b-txt {
        left: 12%;
        width: 450px;
    }
        .homep-b-txt .header-style {
            font-size: 1.8rem;
            line-height: 1.1;
        }
}

@media (min-width: 1500px) {
    .homep-b-txt {
        left: 15%;
        width: 500px;
    }
        .homep-b-txt .header-style {
            font-size: 2rem;
            line-height: 1.1;
        }
}







#features {
    background-color: #efeeef;
}

#recentdesigners {
    background: linear-gradient(45deg, #2937f0, #9f1ae2) !important;
    color: white !important;
}



#whatisflair .text-inner {
    max-width: 500px;
    margin: 0 auto;
    padding-top: 0px;
}
@media (min-width: 768px) {
    #whatisflair .text-inner {
        padding-top: 120px;
    }
}
@media (min-width: 992px) {
    #whatisflair .text-inner {
        padding-top: 150px;
    }
}
@media (min-width: 1200px) {
    #whatisflair .text-inner {
        padding-top: 200px;
    }
}



.test {
    color: #fff;
    /*
    background-color:#000;
    */
    background: linear-gradient(-45deg, #000, #241a1a, #24201a, #1e241a, #1a2424, #231a24);
    background-size: 400% 400%;
    animation: home-gradient 100s ease infinite;
}
    .test a {
        color: #999;
    }

    @keyframes home-gradient {
        0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}
