.editor-topmenu {
    padding: 0.8rem 1.2rem;
    background-color: rgba(255,255,255, 0.7);
    border-bottom-right-radius: 0.5rem !important;
}

.cdx-settings-divider {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
}
    .cdx-settings-divider:last-child {
        margin-bottom: 0px !important;
    }


@media (hover: hover) {
    .ce-toolbar__plus,
    .ce-toolbar__settings-btn {
        background-color: rgba(255, 255, 255, 0.5);
    }
}




.cdx-textinput[contentEditable="true"] {
    display: block;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    outline: none;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

    .cdx-textinput[contentEditable="true"][data-placeholder]::before {
        position: absolute !important;
        content: attr(data-placeholder);
        color: #707684;
        font-weight: normal;
        display: none;
    }

    .cdx-textinput[contentEditable="true"][data-placeholder]:empty::before,
    .cdx-textinput.empty[contentEditable="true"][data-placeholder]::before {
        display: block;
    }

    .cdx-textinput[contentEditable="true"][data-placeholder]:empty:focus::before,
    .cdx-textinput.empty[contentEditable="true"][data-placeholder]:focus::before {
        display: none;
    }


