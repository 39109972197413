.side-panel {
    padding:0;
    position: fixed;
    top:0;
    right:0;
    height:100%;
    width:100%;
    z-index:9999;
}


.side-panel__content {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    height: 100%;
    width: 70%;
    max-width: 600px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 2rem rgba(0, 0, 255, 0.1);
    /*transform: translateX(-200%);
    transition: transform 300ms linear;*/
    overflow: auto;
}
.side-panel__overlay {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.4;
}


.slideanim-enter .side-panel__content {
    transform: translateX(600px);
}
.slideanim-enter.slideanim-enter-active .side-panel__content,
.slideanim2-enter.slideanim2-enter-active .side-panel__content {
    -webkit-animation: slide-in 250ms forwards;
    animation: slide-in 250ms forwards;
}

.slideanim-exit.slideanim-exit-active .side-panel__content,
.slideanim2-exit.slideanim2-exit-active .side-panel__content {
    -webkit-animation: slide-out 250ms forwards;
    animation: slide-out 250ms forwards;
}

@keyframes slide-in {
    0% {
        transform: translateX(600px);
    }

    100% {
        transform: translateX(0);
    }
}

@-webkit-keyframes slide-in {
    0% {
        -webkit-transform: translateX(600px);
    }

    100% {
        -webkit-transform: translateX(0);
    }
}


@keyframes slide-out {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(600px);
    }
}

@-webkit-keyframes slide-out {
    0% {
        -webkit-transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(600px);
    }
}






.slideanim-enter .side-panel__overlay {
    opacity: 0;
}

.slideanim-enter.slideanim-enter-active .side-panel__overlay,
.slideanim2-enter.slideanim2-enter-active .side-panel__overlay {
    -webkit-animation: fade-in 250ms forwards;
    animation: fade-in 250ms forwards;
}

.slideanim-exit.slideanim-exit-active .side-panel__overlay,
.slideanim2-exit.slideanim2-exit-active .side-panel__overlay {
    -webkit-animation: fade-out 250ms forwards;
    animation: fade-out 250ms forwards;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.4;
    }
}

@keyframes fade-out {
    0% {
        opacity: 0.4;
    }

    100% {
        opacity: 0;
    }
}

