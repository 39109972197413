a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

.sidemenu .sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: 600;
    padding: 1.5rem 1.5rem 0.375rem;
}

.navbar-brand {
    background: url(https://res.cloudinary.com/flairfashion/image/upload/c_scale,f_auto,h_100,q_auto:best/v1625645483/TestSite/Logos/flair-designer-portal.jpg) no-repeat 0 0;
    width: 160px;
    height: 29px;
    background-size: 160px 29px;
    display: block;
    text-indent: -999px;
    overflow: hidden;
    margin: 1.5rem auto 1.5rem 0;
}


@media (min-width: 768px) {
    header {
        position: fixed;
    }
    .sidemenu {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 100;
        padding: 0;
        background-color:#fff;
        width:228px;
        margin-bottom:0 !important;
    }
        .sidemenu.box-shadow {
            box-shadow: inset -1px 0 0 rgb(0 0 0 / 10%);
        }

        .sidemenu .sidebar-sticky {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            /*height: calc(100vh - 48px);*/
            height: 100vh;
            padding-top: .5rem;
            overflow-x: hidden;
            overflow-y: auto;
            margin:0;
            padding:0;
        }

        .sidemenu .navbar-nav {
            flex-direction: column;
        }
        .sidemenu .nav-link {
            display: block;
            padding: 0.25rem 1.5rem !important;
            text-decoration:none;
        }

    .navbar-brand {
        background: url(https://res.cloudinary.com/flairfashion/image/upload/c_scale,f_auto,h_100,q_auto:best/v1625645483/TestSite/Logos/flair-designer-portal.jpg) no-repeat 0 0;
        width: 160px;
        height: 29px;
        background-size: 160px 29px;
        display: block;
        text-indent: -999px;
        overflow: hidden;
        margin: 1.5rem auto 1.5rem 1.5rem;
    }
}
